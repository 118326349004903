<template>
  <div class="text-center">
    <v-card outlined style='border-radius: 18px'>
      <v-card-title primary-title class='primary white--text py-3' >
        Próximos cursos
      </v-card-title>
      <v-card-text>
        <card-loading-transparent v-if="loading" />
        <v-data-table :headers="headers" :items="courses"  item-key="data" :search="search" v-if="!loading">
          <template v-slot:[`item.data`]="{ item }">
            {{ $dayjs(item.start_date).format('LL') }}
          </template>
          <template v-slot:top>
            <v-text-field  v-model="search" label="Filtrar evento. ( Data, cidade e status )" class="mx-4"></v-text-field>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <div class="d-flex align-center">
              <v-chip label small dark :color="status_map[item.status].color">
                {{status_map[item.status].title}}
              </v-chip>
              <div class="ml-2" v-if="status_map[item.status].icon">
                <v-btn icon :to="status_map[item.status].action(item.id)"><v-icon small>{{status_map[item.status].icon}}</v-icon></v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>  
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import Api from '@/api/index'

  export default {
    data () {
      return {
        search: '',
        loading: false,
        courses: [],
        status_map: {
          active: {
            title: 'Ativo', 
            color: 'primary', 
            icon: 'mdi-open-in-new', 
            action: (course_id) => ({name: 'Select', params: {id: course_id}}) 
          }, 
          selecting: {
            title: 'Seleção', 
            color: 'primary', 
            icon: 'mdi-open-in-new', 
            action: (course_id) => ({name: 'Select', params: {id: course_id}}) 
          }, 
          done_selection: {
            title: 'Seleção pronta', 
            color: 'primary', 
            icon: 'mdi-open-in-new', 
            action: (course_id) => ({name: 'Select', params: {id: course_id}})
          }, 
          in_progress: {
            title: 'Em andamento', 
            color: 'primary', 
            icon: 'mdi-open-in-new', 
            action: (course_id) => ({name: 'StudentRating', params: {id: course_id}})
          }, 
          done: {
            title: 'Finalizado', 
            color: 'success', 
            icon: 'mdi-open-in-new', 
            action: (course_id) => ({name: 'StudentRating', params: {id: course_id}})
          }, 
          suspended: {
            title: 'Suspenso', 
            color: 'error', 
            icon: '', 
            action: (course_id) => ({name: 'Select', params: {id: course_id}})
          }
        },
      }
    },
    computed: {
      headers () {
        return [
          { text: 'Nome', value: 'name'},
          { text: 'Data', value: 'start_date', align: 'center'},
          { text: 'Status', value: 'status', align: 'center'},
        ]
      },
    },
    methods: {
      getData(){
        this.loading = true
        let filter = {
          start_date: this.$dayjs().add(0, 'month').startOf('month').format('YYYY-MM-DD'),
          end_date: this.$dayjs().add(1, 'month').endOf('year').format('YYYY-MM-DD'),
        }
        setTimeout(() => {
          Api.Course.index(filter).then(r=>r.data).then(d => {
            this.courses = d.map((obj)=> { 
              let course_date_mapped = {...obj,
              start_date: this.$dayjs(obj.start_date).format('LL')
              }
              return course_date_mapped
            })
          })
          this.loading = false
        }, 600);
      },
      ...mapActions({
        indexCourses: 'Course/index'
      })
    },

    mounted () {
      this.getData()
    },

    watch: {
      all_courses(nv) {
        this.courses = nv
      }
    },
  }
</script>

<style lang="scss" scoped>

.text-center{
  font-size: 12px;
  font-weight: 600;
}




</style>