<template>
  <div>
    <v-row class="px-0">
      
      <v-col>
        <card-loading v-if="loading" card_color="white" text_color="primary--text" loading_color="primary"/>
        <Inscriptions :data="all_data.inscriptions" style="height: 100%" v-if="!loading"/>
      </v-col>

      <v-col>
        <card-loading v-if="loading" card_color="white" text_color="primary--text" loading_color="primary"/>
        <Homologated :data="all_data.homologated" style="height: 100%" v-if="!loading"/>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import Api from '@/api'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'FinanceInfo',

  components: {
    Homologated: () => import('./counter_home_infos/CountHomologated.vue'),
    Inscriptions: () => import('./counter_home_infos/CountInscriptions.vue'),
  },

  props: {
    start_date: {
      type: String,
      default: ()=>null
    },
    end_date: {
      type: String,
      default: ()=>null
    }
  },

  data() {
    return {
      loading: false,
      loadings: {
        balance: false,
        income: false,
        outcome: false,
        goals: false,
        savings: false,
      },
      all_data: {
        balance: null,
        income: null,
        outcome: null,
        goals: null,
        savings: null,
      }
    }
  },

  methods: {
    getData(){
      this.getDashCounter()
    },
    getDashCounter(kind='home', filter = {}){
      this.loading = true
      Api.DashCounter.show(kind, filter).then(r=>r.data).then(d=>{
        this.all_data = {...d}
      }).catch((err) => {
        console.log(err);
      }).finally(()=>{
        this.loading = false
      })
    }
  },
  computed: {
    names() {
      return null
    },
    ...mapState({
      activeCompanyId: state => state.App.activeCompanyId
    }),
  },

  mounted () {
    this.getData()
  },
  watch: {
    start_date(){
      this.refreshData()
    },
    activeCompanyId(){
      this.refreshData()
    }
  },
  
}
</script>

<style lang="scss" scoped>

</style>